import { Dictionary, LogArgument, Payload } from 'rollbar';

type Frame = {
    filename: string;
}

export interface ExtendedPayload extends Payload {
    body: {
        trace: {
            frames: Frame[];
        };
    };
}

export const OBJECT_NOT_FOUND_MS_SAFE_LINK_ERROR = 'Object Not Found Matching Id:'; // Caused by Outlook Safe Links. More info: https://github.com/getsentry/sentry-javascript/issues/3440

const IGNORED_MESSAGE_ERRORS = [
    OBJECT_NOT_FOUND_MS_SAFE_LINK_ERROR
];

const IGNORED_EXTERNAL_SCRIPTS = ['jsQuilting'];

/**
 * filter errors to RollBar; it has to return false in order to log errors
 */
export const checkRollbarIgnoreFilter = (
    isUncaught: boolean,
    args: LogArgument[],
    payload: Dictionary,
): boolean => {
    const message: string = args[0] as string;

    return isIgnoredError(message) || isFromExternalScript(payload as ExtendedPayload);
};

const isFromExternalScript = (payload: ExtendedPayload): boolean => {
    try {
        return payload.body.trace.frames.some(frame => IGNORED_EXTERNAL_SCRIPTS.some((externalScript) => frame.filename.includes(externalScript)));
    } catch (e) {
        return false;
    }
};

const isIgnoredError = (message: string): boolean => {
    return IGNORED_MESSAGE_ERRORS
        .some((ignoredMessageError) => message.toLowerCase().includes(ignoredMessageError.toLowerCase()));
};
