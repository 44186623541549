// @ts-strict-ignore
import { EXTENSION_WAS_INSTALLED_EVENT } from './constants';
import { InstallButtonConfig } from './InstallButtonConfig';
import { browser } from '../browser/detectBrowser';
import { tryJsonParse } from '../../utils/tryJsonParse';

const SELECTORS = {
    installButton: '.install',
    installButtonConfiguration: (id) => `${id}-button-configuration`
};

interface BrowserConfig {
    (config: InstallButtonConfig): {
        install: () => void;
    };
}

interface SupportedBrowserOptions {
    chrome: BrowserConfig;
    mobile: BrowserConfig;
}

let isExtensionInstalled = false;

document.querySelector('body').addEventListener(EXTENSION_WAS_INSTALLED_EVENT, () => {
    isExtensionInstalled = true;
});

window.addEventListener('load', () => {

    const supportedBrowsers: SupportedBrowserOptions = {
        chrome(config){
            if(config.isNew) {
                return {
                    install: () => {
                        try {
                            window.location.href = config.onInstalledLink;
                        } catch (e) { /* empty */ }
                    }
                };
            }
            const url = isExtensionInstalled ? config.onInstalledLink : config.chromeStoreLink;

            return {
                install: () => {
                    try {
                        window.location.href = url;
                    } catch (e) { /* empty */ }
                }
            };
            
        },
        mobile(config){
            return {
                install: () => {
                    window.location.href = config.addonUrl;
                },
            };
        }
    };

    const getSupportedBrowser = (): BrowserConfig | null => {
        if (browser.isChrome() || browser.isEdgeChromium()) {
            return supportedBrowsers.chrome;
        }
        if (browser.isMobileDevice()) {
            return supportedBrowsers.mobile;
        }
        return null;
    };

    const installEvent = (config) => event => {
        event.preventDefault();
    
        const supportedBrowser = getSupportedBrowser();
    
        if (!supportedBrowser) {
            window.location = config.unsupportedLink;
            return;
        }

        const browser = supportedBrowser(config);
        browser.install();
    };

    const init = () => {
        const installButtons = document.querySelectorAll(SELECTORS.installButton) as NodeListOf<HTMLAnchorElement>;

        installButtons.forEach(installButton => {
            const { id } = installButton;
            const configElement = document.getElementById(SELECTORS.installButtonConfiguration(id));
            const config = tryJsonParse<InstallButtonConfig, null>(configElement?.innerHTML, { message: 'Could not parse button config', value: null });

            if (config && installButton.dataset.upgradable !== 'false') {
                installButton.addEventListener('click', installEvent(config));
            }
        });
    };

    init();
});
