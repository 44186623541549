// @ts-strict-ignore
import { isDebugMode } from '../debug/isDebug';
import { format } from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import { configureSnowplowTrackers, getSnowplowConfig } from './snowplow';
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';

export const TIMESTAMP_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx';

export const BI_DASHBOARD_CLICKED_UPGRADE_PLAN = 'dashboard_clicked_upgrade_plan';

// https://github.com/snowplow/snowplow/wiki/2-Specific-event-tracking-with-the-Javascript-tracker#38-tracking-custom-self-describing-unstructured-events
const snowplowTrack = (name, userId, data = {}) => {
    const required = {
        timestamp: format(new Date(), TIMESTAMP_FORMAT),
        platform: 'gmail_web_v1',
        user_id: userId,
    };

    const json = {
        schema: 'iglu:io.mailtrack/mailtrack_event/jsonschema/1-0-0',
        data: {
            name,
            ...required,
            json_data: !isEmpty(data) ? JSON.stringify(data) : undefined
        },
    };

    configureSnowplowTrackers();

    getSnowplowConfig().forEach(conf => {
        if (isDebugMode()) {
            // eslint-disable-next-line no-console
            console.group('[SnowPlow Event]');
            // eslint-disable-next-line no-console
            console.table({ name, ...required, ...data });
            // eslint-disable-next-line no-console
            console.groupEnd();
            return;
        }

        if(Math.random() < conf.ratio) {
            trackSelfDescribingEvent({ event: json }, [conf.namespace]);
        }
    });
};

export default snowplowTrack;