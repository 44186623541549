// @ts-strict-ignore
export const tryJsonParse = <T, F>(jSonContent, fallback = { message: 'Could not parse JSON', value: null }): T | F => {
    try {
        return JSON.parse(jSonContent);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(fallback.message, e);

        return fallback.value;
    }
};
