import { appEnv } from '../../globals';

export enum AppEnvironment {
    PROD = 'prod', 
    STAGING = 'staging',
    CANARY = 'canary',
    DEV = 'dev'
}

const environments = Object.values(AppEnvironment);

export const isDev = () => appEnv === AppEnvironment.DEV;

export const isProd = () => appEnv === AppEnvironment.PROD;

export const getRuntimeEnvironment = () => environments.find(env => env === appEnv) || AppEnvironment.DEV;