// @ts-strict-ignore
import { logError } from '../../utils/logError';
import { getUserConfigNode } from './dom/getUserConfigNode';
import { errorWithPrefix } from '../../error/errorWithPrefix';

function getUserConfig() {
    try {
        const userConfigNode = getUserConfigNode();

        if (!userConfigNode) {
            throw new Error('No user config node in page');
        }

        return JSON.parse(userConfigNode.innerHTML);
    } catch(e) {
        throw errorWithPrefix(e, 'Error getting user config');
    }
}

export function getUserId(shouldFail = true) {
    let userId;

    try {
        const userConfig = getUserConfig();

        if (!userConfig.userId) {
            throw new Error('No userId in user config');
        }

        userId = userConfig.userId;
    } catch(e) {
        if (shouldFail) {
            logError(e);
        }
    }

    return userId;
}

export function getSnowplowUserId() {
    try {
        const userConfig = getUserConfig();

        if (!userConfig.snowplowUserId) {
            throw new Error('No snowplowUserId in user config');
        }

        return userConfig.snowplowUserId;
    } catch(e) {
        logError(e);
    }
}
