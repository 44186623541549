import '../common/components/extension/installButton';
import { listenForCTAClicks } from './components/ctaButtons';
import { logger } from '../common/logger/logger';
import { modifyHeaderOnScroll } from '../newWeb/landings/modifyHeaderOnScroll';

logger.setContext('home');

listenForCTAClicks();

window.onscroll = modifyHeaderOnScroll;
