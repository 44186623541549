import { getSnowplowUserId } from '../../common/components/user/userConfig';
import snowplowTrack from '../../common/components/analytics/snowplowTrack';

const EVENT_NAME = 'cta_button_clicked';
const BUTTONS_SELECTOR = '.ms-button.install, .mt-button-v2.install';

export const listenForCTAClicks = () => {
    const ctaButtons = document.querySelectorAll<HTMLElement>(BUTTONS_SELECTOR);

    ctaButtons?.forEach((button) => {
        button.addEventListener('click', () => handleCTAClick(button));
    });
};

const handleCTAClick = (button: HTMLElement) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const position = button.dataset.position ?? 'unknown';
    const landingPage = button.dataset.landingPage ?? 'unknown';
    const source = urlParams.get('utm_source') ?? 'unknown';
    const medium = urlParams.get('utm_medium') ?? 'unknown';

    snowplowTrack(EVENT_NAME, getSnowplowUserId(), {
        position,
        landingPage,
        source,
        medium
    });
};