import { Data, LoggerConfig, LoggerInstance, RichLogData } from './logger';
import { deploymentVersion, loggerSafeHosts } from '../globals';
import { getRuntimeEnvironment, isProd } from '../components/environment/environment';
import Rollbar from 'rollbar';
import { checkRollbarIgnoreFilter } from './checkRollbarIgnoreFilter';

const ROLLBAR_ACCESS_TOKEN = '4f9fd138d1274d1abd494a30021c1b2c';
const DEFAULT_CONTEXT = 'Backend';
const MAX_ITEMS = 15;
const CAPTURE_TELEMETRY_LOG_LEVEL = 'info';

const getHostsSafeList = (): string[] => loggerSafeHosts ?? [] as string[];

export const rollbarLogger: () => LoggerInstance = () => {
    const rollbar = Rollbar.init({
        accessToken: ROLLBAR_ACCESS_TOKEN,
        captureUnhandledRejections: true,
        captureUncaught: true,
        payload: {
            context: DEFAULT_CONTEXT,
            client: {
                javascript: {
                    source_map_enabled: true,
                    ...(isProd() && deploymentVersion) && {
                        code_version: deploymentVersion
                    }
                },
            },
        },
        environment: getRuntimeEnvironment(),
        maxItems: MAX_ITEMS,
        scrubTelemetryInputs: true,
        checkIgnore: checkRollbarIgnoreFilter,
        hostSafeList: getHostsSafeList(),
    });

    const error = (error: Error, data: RichLogData) => rollbar.error(error.message, error, data);
    const info = (message: string, data: RichLogData) => rollbar.info(message, data);
    const captureEvent = (data: Data) => rollbar.captureEvent(data, CAPTURE_TELEMETRY_LOG_LEVEL);
    const configure = (config: LoggerConfig) => rollbar.configure(config);

    return {
        error,
        info,
        captureEvent,
        configure
    };
};
