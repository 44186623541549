// @ts-strict-ignore
import { clearUserData, newTracker } from '@snowplow/browser-tracker';

let isConfigured = false;

const getSnowplowData = () => {
    const SNOWPLOW_ID = '#snowplow-init';
    const config = document.querySelector(SNOWPLOW_ID);

    if (!config) {
        // eslint-disable-next-line no-console
        console.error('Cannot parse snowplow element');
        return;
    }

    return JSON.parse(config.innerHTML);
};

const isAnonymousTrackingEnabled = () => {
    const snowplowData = getSnowplowData();

    return snowplowData?.anonymousTracking;
};

export const getSnowplowConfig = () => {
    const snowplowData = getSnowplowData();

    return snowplowData?.config || [];
};

export const configureSnowplowTrackers = () => {
    if(isConfigured){
        return;
    }

    const anonymousTracking = isAnonymousTrackingEnabled();

    getSnowplowConfig().forEach(conf => {
        newTracker(conf.namespace, conf.host, {
            anonymousTracking,
            encodeBase64: true
        });
    });

    if (anonymousTracking) {
        clearUserData();
    }

    isConfigured = true;
};

window.addEventListener('CookiebotOnAccept', function() {
    if (window.Cookiebot.consent?.statistics) {
        configureSnowplowTrackers();
    }
}, false);
