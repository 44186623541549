export const modifyHeaderOnScroll = () => {
    const header = document.querySelector<HTMLHeadElement>('header');

    if(!header) {
        return;
    }

    if (document.body.scrollTop >= 10 || document.documentElement.scrollTop >= 10) {
        header.classList.add('scrolled');
    } else {
        header.classList.remove('scrolled');
    }
};
