/* eslint-disable no-console */
import { LoggerConfig, LoggerInstance, RichLogData } from './logger';

let context = '';

const buildMessage = (message: string) => {
    message = message || '';

    return `${context}${message}`;
};

const info = (message: string, infoData: RichLogData) => {
    console.info(buildMessage(message));

    const extraData = infoData.data;

    if(extraData) {
        console.info(extraData);
    }
};

const error = (error: Error, errorData: RichLogData) => {
    console.error(buildMessage(error.message));

    const extraData = errorData.data;

    if (extraData) {
        console.error(extraData);
    }
};

const captureEvent = () => {};

const configure = (config: LoggerConfig) => {
    const configContext = config?.payload?.context;
    if (configContext) {
        context = `${configContext} - `;
    }
};

export const consoleLogger: () => LoggerInstance = () => ({
    info,
    error,
    captureEvent,
    configure
});
