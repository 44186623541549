const PRODUCTION_HOSTNAMES = [
    'mailtrack.io',
    'maildoc.io',
];

export function isDebugMode() {
    try {
        return document.querySelector('#debug-mode') !== null;
    } catch (e) {
        // as fallback if hostname is different of any of our production hostnames we will mark this as debug mode
        return !PRODUCTION_HOSTNAMES.includes(window.location.hostname);
    }
}
