export const browser = {
    getBrowser: function() {
        const userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.indexOf('android') !== -1) {
            return browser.constant.android;
        }
        if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
            return browser.constant.ios;
        }
        if (userAgent.includes('firefox/') && !userAgent.includes('seamonkey/')) {
            return browser.constant.firefox;
        }
        if (userAgent.indexOf('opr/') !== -1 || userAgent.indexOf('opera') !== -1) {
            return browser.constant.opera;
        }
        if (/edg([ea]|ios)/i.test(userAgent)) {
            return browser.constant.edge;
        }
        if (/edg(?![ea]|ios)/i.test(userAgent) && userAgent.indexOf('chrome') !== -1) {
            return browser.constant.edgeChromium;
        }
        if (userAgent.indexOf('chrome') !== -1) {
            return browser.constant.chrome;
        }

        return null;
    },
    isChrome: function() {
        return browser.constant.chrome === browser.getBrowser();
    },
    isSafari: function() {
        return (window as typeof window & { safari: string }).safari !== undefined;
    },
    isEdgeChromium: function() {
        return browser.constant.edgeChromium === browser.getBrowser();
    },
    isMobileDevice: function() {
        return (
            browser.constant.android === browser.getBrowser() ||
            browser.constant.ios === browser.getBrowser()
        );
    },
    isUnsupported: function() {
        const isUnknownBrowser = null === browser.getBrowser();

        return isUnknownBrowser || browser.isMobileDevice();
    },
    constant: {
        chrome: 'CHROME',
        firefox: 'FIREFOX',
        opera: 'OPERA',
        edge: 'EDGE',
        edgeChromium: 'EDGE CHROMIUM',
        android: 'ANDROID',
        ios: 'IOS',
    },
};
