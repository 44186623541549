import { isDev } from '../components/environment/environment';
import { getUserId } from '../components/user/userConfig';
import { consoleLogger } from './consoleLogger';
import { rollbarLogger } from './rollbarLogger';

const GENERIC_ERROR = 'Generic error';

interface ExperimentalNavigator {
    connection: {
        downlink: number;
        effectiveType: string;
        saveData: boolean;
        type: string;
    };
    deviceMemory: number;
}

export interface Logger {
    error: (data: ErrorData) => void;
    info: (data: InfoData) => void;
    captureEvent: (data: Data) => void;
    configure: (config: LoggerConfig) => void;
    setContext: (context: string) => void;
    setScrubPaths: (paths: string[]) => void;
}

export interface LoggerInstance {
    error: (error: Error, data: RichLogData) => void;
    info: (message: string, data: RichLogData) => void;
    captureEvent: (data: Data) => void;
    configure: (config: LoggerConfig) => void;
}

export interface LoggerConfig {
    payload?: {
        context: string;
    };
    scrubPaths?: string[];
}

export interface Data {
    [key: string]: unknown;
}

export interface UserData {
    userId?: string;
}

export interface LogData {
    data?: Data;
}

export interface RichLogData extends LogData {
    user: UserData;
    context: Data;
}

export interface ErrorData extends LogData {
    error: Error | string;
}

export interface InfoData extends LogData  {
    message: string;
}

const buildLogError = (loggers: LoggerInstance[]) => {
    return (data: ErrorData): void => {
        let error = data.error;

        const errorData: RichLogData = {
            data: data.data,
            user: getUserData(),
            context: getContext(),
        };

        if (!error || !(error instanceof Error)) {
            error = generateErrorIfWrongType(error);
        }

        loggers.forEach((logger) => logger.error((error as Error), errorData));
    };
};

const buildLogInfo = (loggers: LoggerInstance[]) => {
    return (data: InfoData): void => {

        const infoData: RichLogData = {
            data: data.data,
            user: getUserData(),
            context: getContext(),
        };

        loggers.forEach((logger) => logger.info(data.message, infoData));
    };
};

const buildCaptureEvent = (loggers: LoggerInstance[]) => {
    return (data: Data): void => {
        loggers.forEach((logger) => logger.captureEvent(data));
    };
};

const buildConfigure = (loggers: LoggerInstance[]) => {
    return (config: LoggerConfig): void => {
        loggers.forEach((logger) => logger.configure(config));
    };
};

const buildSetContext = (loggers: LoggerInstance[]) => {
    return (context: string): void => {
        loggers.forEach((logger) => logger.configure({ payload: { context } } ));
    };
};

const buildSetScrubPaths = (loggers: LoggerInstance[]) => {
    return (scrubPaths: string[]): void => {
        loggers.forEach((logger) => logger.configure({ scrubPaths } ));
    };
};

const getUserData = (): UserData => ({
    userId: getUserId(false),
});

const getContext = (): Data => {
    const experimentalNavigator = navigator as unknown as ExperimentalNavigator;
    const connection = experimentalNavigator.connection;

    const networkConnection = {
        networkDownlinkBandwidth: connection?.downlink,
        networkEffectiveType: connection?.effectiveType,
        networkSaveData: connection?.saveData,
        networkType: connection?.type,
    };

    const deviceInfo = {
        deviceOnline: navigator.onLine,
        ...networkConnection,
        deviceMemory: experimentalNavigator.deviceMemory,
    };

    return {
        ...locationData(),
        ...deviceInfo,
    };
};

const locationData = (): Data => {
    if (!location) {
        return {};
    }

    const { hash, pathname, host, search } = location;

    return {
        hash,
        pathname,
        host,
        search,
    };
};

const generateErrorIfWrongType = (error: unknown): Error => {
    if (typeof error === 'string') {
        return new Error(`${GENERIC_ERROR} - ${error as string}`);
    }
    return new Error(GENERIC_ERROR);
};

export const getLogger = (): Logger => {
    const loggers = isDev()
        ? [ consoleLogger() ]
        : [ rollbarLogger() ];

    const error = buildLogError(loggers);
    const info = buildLogInfo(loggers);
    const captureEvent = buildCaptureEvent(loggers);
    const configure = buildConfigure(loggers);
    const setContext = buildSetContext(loggers);
    const setScrubPaths = buildSetScrubPaths(loggers);

    return {
        error,
        info,
        captureEvent,
        configure,
        setContext,
        setScrubPaths,
    };
};

export const logger = getLogger();
